import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const UserLogin = Loadable(lazy(() => import("./UserLogin")))
const Koefisien = Loadable(lazy(() => import("./Koefisien")))
const Kategori = Loadable(lazy(() => import("./Kategori")))
const RoleMenu = Loadable(lazy(() => import("./RoleMenu")))

const pengaturanRoutes = [
    {
        path: '/pengaturan/user_login',
        element: <UserLogin />,
    },
    {
        path: '/pengaturan/koefisien',
        element: <Koefisien />
    },
    {
        path: '/pengaturan/kategori',
        element: <Kategori />
    },
    {
        path: '/pengaturan/role_menu',
        element: <RoleMenu />
    }
]

export default pengaturanRoutes
